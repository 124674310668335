// Bootstrap 4/5 issue with react-popper
.arrow {
  @extend .tooltip-arrow;
}

.tooltip-inner {
  color: $gray-100;
  font-size: 14px;
  letter-spacing: 0.16px;
  line-height: 18px;

  .cf-investment-header {
    color: $gray-100;
    margin-bottom: 8px;
    height: 18px;
    width: 220px;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 18px;
  }

  //min-width: 262px !important;
  text-align: left !important;
  padding: 20px 20px 8px 20px;

  .dismiss {
    display: block;
    margin-bottom: 10px;

    cursor: pointer;
    color: #00FF99;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.16px;
    line-height: 18px;
    text-decoration: underline;
  }
}

.tooltip-hide-on-mobile {
  @include media-breakpoint-down(lg) {
    display: none !important;
  }
}