.cf-investment-chart {
  width: 100%;
  // min-height: 340px;
  padding-bottom: 12px;
  border-bottom: 3px solid $cyan;
}

.cf-investment-usd,
.cf-investment-usd-md,
.cf-investment-usd-lg {
  font-weight: $font-weight-bold;
  font-size: 40px;
  max-width: 99%;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.cf-investment-usd {
  padding-top: 10px;
  font-size: 28px;
}

.cf-investment-usd-md {
  font-size: 35px;
  letter-spacing: 0.39px;
}

.cf-investment-stats {

  .down,
  .up {
    padding: 3px 8px;
    font-weight: $font-weight-bold;
    font-size: $font-size-sm;
    margin: 0 20px 0 0;
    display: inline-block;
  }

  .down::before,
  .up::before {
    content: "";
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='currentColor' class='bi bi-triangle-fill' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M7.022 1.566a1.13 1.13 0 0 1 1.96 0l6.857 11.667c.457.778-.092 1.767-.98 1.767H1.144c-.889 0-1.437-.99-.98-1.767L7.022 1.566z'/%3E%3C/svg%3E");
    margin-right: 6px;
    top: 1px;
    display: inline-block;
    width: 12px;
    height: 12px;
  }

  .up {
    background: rgb(81, 238, 232);
    background: linear-gradient(45deg, rgba(173, 250, 220, 1) 0%, rgba(173, 250, 232, 1) 100%);
  }

  .down {
    background: rgb(81, 238, 232);
    background: linear-gradient(45deg, rgba(250, 197, 201, 1) 0%, rgba(248, 177, 230, 1) 100%);

    &::before {
      transform: rotate(180deg);
    }
  }
}

.cf-investment-muted {
  font-size: $font-size-sm;
  color: $gray-500;
}

.cf-investment-header-sm {
  display: block;
}

.cf-investment-header-2 {
  font-weight: $font-weight-bold;
  font-size: $font-size-base;
  margin: 15px 0 25px 0;
  display: block;
}

.cf-investment-coins {
  margin: 0 20px 0 10px;
}

.cf-investment-coin {
  border-bottom: 1px solid $gray-400;
  height: 82px;
  padding-top: 20px;

  img {
    top: 4px;
    position: relative;
  }

  .currency {
    // margin-left: 12px;
  }

  .currency-name {
    display: block;
    // margin-left: 74px;
    font-size: $font-size-sm;
    color: $gray-600;
  }

  .percentage {
    position: relative;
    top: -36px;
    font-weight: $font-weight-bold;
  }
}

.cf-investment-coin {
  .crypto-icon {
    -webkit-filter: grayscale(100%) contrast(1.5) saturate(14.5) opacity(.75);
    filter: grayscale(100%) contrast(1.5) saturate(14.5) opacity(.75);
    display: inline-block;
    width: 36px;
    height: 36px;
    //overflow: hidden;
    transition: all ease-in-out .3s;
  }

  //&:hover {
  //  .crypto-icon {
  //    transition: all ease-in-out .1s;
  //    -webkit-filter: none;
  //    filter: none;
  //  }
  //}
}

.cf-investment-chart-zoom {
  margin-top: 15px;

  .btn {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.06px;
    color: $gray-600;
    line-height: 22px;

    &.active {
      color: $green;
      background: #474747;
    }
  }
}
body.portfolio #root,
.cf-portfolio-body {
  min-height: 65vh !important;
}