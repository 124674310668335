#mc-embedded-subscribe-form2,
#mc-embedded-subscribe-form {
  label {
    text-transform: uppercase;
  }

  input[type=text],
  input[type=email],
  select,
  textarea {
    background: $white;
    max-width: 650px;
  }
  display: block;
  margin-bottom: 50px;
}

.cf-footer-subscribe {
  margin: 0 auto;
  max-width: 557px;
  padding-bottom: 50px;
  #mc-embedded-subscribe-form2 {
    margin: 30px auto;
    input[type=text],
    input[type=email],
    {
      background: $white;
      max-width: 407px;
    }
    .btn {
      max-width: 150px;
      border-radius: 0 3px 3px 0;
    }
    label {
      text-align:left !important;
      left: 0;
      width: 100%;
      color: $gray-300;
      margin: 0 0 4px 0;
    }
  }
}