.anim {
  transform-origin: center;
  transform-style: preserve-3d;
  transform: perspective(800px);
  perspective-origin: center;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-6-25 18:36:56
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

.bounce-in-top {
  animation: bounce-in-top 1.1s both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-6-25 18:37:42
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounce-in-top
 * ----------------------------------------
 */
@keyframes bounce-in-top {
  0% {
    transform: translateY(-500px);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    transform: translateY(0);
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    transform: translateY(-65px);
    animation-timing-function: ease-in;
  }
  72% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }
  81% {
    transform: translateY(-28px);
    animation-timing-function: ease-in;
  }
  90% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }
  95% {
    transform: translateY(-8px);
    animation-timing-function: ease-in;
  }
  100% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2021-6-25 18:38:55
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

.tilt-in-top-1 {
  animation: tilt-in-top-1 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.tilt-in-top-2 {
  animation: tilt-in-top-1 .5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/**
 * ----------------------------------------
 * animation tilt-in-top-1
 * ----------------------------------------
 */
@keyframes tilt-in-top-1 {
  0% {
    transform: rotateY(30deg) translateY(-300px) skewY(-30deg);
    opacity: 0;
  }
  100% {
    transform: rotateY(0deg) translateY(0) skewY(0deg);
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2021-6-25 18:41:55
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

.flip-in-hor-bottom {
  animation: flip-in-hor-bottom 2.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.flip-in-hor-bottom-2 {
  animation: flip-in-hor-bottom 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/**
 * ----------------------------------------
 * animation flip-in-hor-bottom
 * ----------------------------------------
 */
@keyframes flip-in-hor-bottom {
  0% {
    transform: rotateX(80deg);
    opacity: 0;
  }
  100% {
    transform: rotateX(0deg);
    opacity: 1;
  }
}

.fade-in-bg-gray {
  animation: fade-in-bg-gray 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes fade-in-bg-gray {
  0%, 90% {
    background: rgba($gray-700, 0);
  }
  100% {
    background: rgba($gray-700, 1);
  }
}
