@import url($web-font-path);

// Serif font
.font-family-serif {
  font-family: $font-family-serif;
  line-height: 1.4;
}

.link,
.a:not(.btn) :not(.nav-link) :not(.navbar-brand),
a:not(.btn) :not(.nav-link) :not(.navbar-brand) {
  color: $gray-700;
  font-weight: $font-weight-bold;
  text-decoration: none;
  border-bottom: 1px solid $green;
  cursor: pointer;
  &:hover {
    color: $gray-700;
    border-bottom: 1px solid $gray-700;
    cursor: pointer;
  }
  &:visited {
    color: $gray-600;
    border-bottom: 1px solid lighten($green, 20);
  }
}

.bg-dark {
  .link,
  .a:not(.btn) :not(.nav-link) :not(.navbar-brand),
  a:not(.btn) :not(.nav-link) :not(.navbar-brand) {
    color: $green;
    text-decoration: none;
    border-bottom: 1px solid $green;
    &:hover {
      color: $light;
      border-bottom: 1px solid $light;
      cursor: pointer;
    }
    &:visited {
      color: $light;
    }
  }
}

.bg-gradient1,
.bg-gradient2 {
  .link:not(.btn),
  .a:not(.btn),
  a:not(.btn) {
    text-decoration: none;
    border-bottom: 1px solid $light;
    color: $dark;
    &:not(.btn):hover {
      color: $dark !important;
      border-bottom: 1px solid $dark;
      cursor: pointer;
    }
    &:not(.btn):visited {
      color: $gray-600;
      border-bottom: 1px solid $light;
    }
  }
}

label {
  font-size: $font-size-base * .85;
  color: $gray-600;
}

h1,
h5 {
  font-family: $font-family-serif;
  line-height: 1.3;
  font-weight: normal;
}

// h2 - used for slogan black-tie...

// h3 - used in investment page, form headings
h3 {
  font-weight: bold;
  font-size: calc(1.2rem + 0.25vw) !important;
  margin-bottom: 2.5rem !important;
  margin-top: 1.3rem !important;
  line-height: 1.8rem;
}

// h4 - for offerings list.
h4 {
  font-weight: lighter;
  font-family: $font-family-serif;
  color: $cyan;
  font-size: $font-size-base * 1.9;
}

// Middle page callout
h5 {
  line-height: 1.45;
  font-weight: lighter;
  font-size: $font-size-base * 1.75;
}

.cf-diamond {
  position: relative;
  &:before {
    content: '';
    height: 1em;
    width: 1em;
    background: $cyan;
    display: block;
    position: absolute;
    transform: rotate(45deg);
    top: 0.3em;
    left: 1em;
    @include media-breakpoint-up(lg) {
      left: 1em;
    }
  }
}

.cf-asterix {
  color: $green;
  &.asterix-lg {
    font-size: 3.4rem !important;
    line-height: .6em;
  }
}

.cf-strong {
  font-size: $font-size-base * 1.1;
}

.cf-percentage-lg {
  font-size: $font-size-base * 5;
  font-weight: bolder;
}

.cf-no-break {
  white-space: nowrap;
  word-break: normal !important;
}

.cf-investment-header {
  color: $gray-500;
  text-transform: uppercase;
  font-size: $font-size-base * .8;
}
