body.home {
  @include media-breakpoint-down(xxl) {
    .navbar-collapse {
      top: 80px;
    }
    .navbar-collapse.collapsing,
    .navbar-collapse.show {
      position: inherit;
      top: auto;
    }
  }
}

.cf-icon-01,
.cf-icon-02,
.cf-icon-03,
.cf-icon-04,
.cf-icon-fund-01,
.cf-icon-fund-02,
.cf-icon-fund-03,
.cf-icon-fund-04,
.cf-icon-fund-05,
.cf-icon-fund-06,
.cf-icon-fund-07 {
  display: block;
  width: 65px;
  height: 65px;
  background-repeat: no-repeat;
  background-size: cover;
}
.cf-icon-01 {
  background-image: url("../img/CF-icon01.svg");
}
.cf-icon-02 {
  background-image: url("../img/CF-icon02.svg");
}
.cf-icon-03 {
  background-image: url("../img/CF-icon03.svg");
}
.cf-icon-04 {
  background-image: url("../img/CF-icon04.svg");
}
.cf-icon-fund-01 {
  background-image: url("../img/CF-icon-fund01.svg");
}
.cf-icon-fund-02 {
  background-image: url("../img/CF-icon-fund02.svg");
}
.cf-icon-fund-03 {
  background-image: url("../img/CF-icon-fund03.svg");
}
.cf-icon-fund-04 {
  background-image: url("../img/CF-icon-fund04.svg");
}
.cf-icon-fund-05 {
  background-image: url("../img/CF-icon-fund05.svg");
}
.cf-icon-fund-06 {
  background-image: url("../img/CF-icon-fund06.svg");
}
.cf-icon-fund-07 {
  background-image: url("../img/CF-icon-fund07.svg");
}
