ul:not(.nav) {
  list-style: none;
  margin: 2em 2em 2em .2em;
  padding: 0;
  li:not(.nav-item) {
    margin: 0;
    margin-bottom: 1em;
    padding-left: 1.5em;
    position: relative;
    &:after {
      content: '';
      height: .5em;
      width: .5em;
      background: $dark;
      display: block;
      position: absolute;
      transform: rotate(45deg);
      top: .6em;
      left: 0;
    }
  }
}

dl {
  border-left: 2px solid $gray-600;
  border-bottom: 2px solid $gray-600;
  height: .8em;
  margin: 3em 0 0 0;

  dt {
    color: $gray-600;
    margin: -0.9em .9em 0 .6em;
    font-weight: $font-weight-light;
    font-size: $font-size-base * .8;
    text-transform: uppercase;
    letter-spacing: .05em;
    white-space: nowrap;
    overflow: visible;
  }
  dd {
    margin: -0.9em 0 0 0;
    font-weight: $font-weight-bold;
    font-size: $font-size-base;
    white-space: nowrap;
    overflow: visible;
  }
}