.cf-fund-list {
  a.cf-fund-link *,
  a.cf-fund-link {
    border-bottom: none !important;
    text-decoration: none !important;
    font-weight: $font-weight-normal !important;

    strong {
      font-weight: $font-weight-bold !important;
    }
  }

  h5.card-title {
    font-weight: $font-weight-lighter !important;
  }

  .card-text {
    font-size: $font-size-sm;
  }
}

//.cf-demo {
//  //.cf-investment-usd,
//  //.cf-investment-stats,
//  //.cf-investment-usd-lg,
//  //.cf-investment-usd-md {
//  //  opacity: .50;
//  //}
//  &.bg-gradient-top {
//    //opacity: .1;
//  }
//}

.cf-demo-window-container {
  margin-bottom: 580px;
  font-size: 85%;
  .cf-demo-window {
    border-radius: 8px;
    min-width: 100%;
    .loading-container,
    .loading-container .row,
    .loading-container .row .col{
      height: 100%;
    }
    //filter: drop-shadow(.5);
    padding-left: 0 !important;
    padding-right: 0 !important;
    overflow-x: hidden;
    overflow-y: hidden;
    filter: drop-shadow(5px 9px 15px rgba(0, 0, 0, 0.2));
    height: 910px;
    margin-bottom: -580px;
    @include media-breakpoint-down(xl) {
      height: 910px;
      // margin-bottom: -600px;
    }

    > .row {
      // padding-left: 0 !important;
      // padding-right: 0 !important;
      // margin-right: -7.8%;
      padding: 10px;
    }
    .cf-fund-sidebar {
      // width: 34.5%
    }

    .cf-demo-expanded {
      // margin-left: $grid-gutter-width;
      margin-right: 0;
    }

    .cf-investment-usd,
    .cf-investment-usd-md,
    .cf-investment-usd-lg {
      font-size: 35px;
    }

    .cf-investment-usd-md {
      font-size: 30px;
    }

    .cf-investment-usd {
      font-size: 23px;
    }
  }
}