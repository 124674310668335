
.bg-gradient1 {
  background: rgb(81, 238, 232);
  background: linear-gradient(180deg, rgba(81, 238, 232, 1) 0%, rgba(82, 255, 160, 1) 100%);
}

.bg-gradient2 {
  background: rgb(81, 238, 232);
  background: linear-gradient(180deg, rgb(84, 244, 244) 0%, rgb(81, 245, 194) 50%, rgb(78, 245, 149) 100%);
}

.bg-pattern {
  margin: 0;
  padding: 0;
  background-repeat: repeat-y;
  background-size: contain;
  background-position: 115% center;
  background-image: url("../img/CF-pattern.svg");
  overflow: visible;
}

.bg-dark-w-green {
  min-height: 500px;
  border-bottom: 10px solid $green;

  .cf-diamond {
    margin: 70px 0;
    padding: 0 70px;
    @include media-breakpoint-down(xl) {
      padding: 0 50px;
    }
    @include media-breakpoint-down(lg) {
      margin: 25px 0 45px 0;
    }
  }
  &.pad {
    > div > div,
    > div {
      padding: 0 5%;
    }
    padding: 0 8%;
  }
  &.pad-sm {
    > div > div,
    > div {
      padding: 0 2%;
    }
    padding: 0 8%;
  }
}

.bg-gradient-offset:after,
.bg-gradient-offset2:after,
.bg-city:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-repeat: no-repeat;
  background-size: 41%;
  background-position-y: 0;
  background-position-x: right;
  @include media-breakpoint-down(xl) {
    background-size: cover;
    background-position: bottom right;
    filter: opacity(65%) brightness(120%);
  }
}

.bg-city:after {
  background-image: url("../img/city.jpg");
}

.bg-gradient-offset2:after,
.bg-gradient-offset:after {
  background-image: linear-gradient(180deg, rgb(84, 244, 244) 0%, rgb(81, 245, 194) 50%, rgb(78, 245, 149) 100%);
  background-size: 31%;
  @include media-breakpoint-down(xl) {
    background-size: cover;
    filter: opacity(50%) brightness(120%);
    height: 150%;
  }
}
.bg-gradient-offset2:after {
  background-size: 43%;
  @include media-breakpoint-down(xl) {
    background-size: cover;
    filter: opacity(50%) brightness(120%);
    height: 150%;
  }
}

.bg-gradient-top {
  border: none;
  padding-top: 10px;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 10px;
    display: block;
    //z-index: -1;
    background-color: $green;
    background-repeat: no-repeat;
    background-image: linear-gradient(-90deg, rgb(84, 244, 244) 0%, rgb(81, 245, 194) 50%, rgb(78, 245, 149) 100%);
  }
  &.cf-demo {
    &:before {
      height: 28px;
      color: rgba(51,51,51,0.5);
      content: "DEMO";
      font-size: 12px;
      font-weight: bold;
      text-align: right;
      padding: 6px;
    }
    &:after  {
      content: "";
      overflow:visible;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 0;
      margin: 10px 50px 0 0;
      height: 18px;
      display: block;
      background-color: $white;
    }
  }
}
.bg-gradient-bar {
  border: none;
  padding-left: 10px;
  background-color: $green;
  background-repeat: no-repeat;
  background-image: linear-gradient(180deg, rgb(84, 244, 244) 0%, rgb(81, 245, 194) 50%, rgb(78, 245, 149) 100%);
}