.btn {
  // The design has a border radius for fields, but not buttons... Not sure which should be the standard.
  border-radius: 0;
  // letter-spacing: .18px;
  //border-bottom: 0 !important;
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    background-color: rgb(51, 51, 51);
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40'><defs><linearGradient id='a'><stop offset='0%' stop-color='%2351eee8'/><stop offset='100%' stop-color='%2352ffa0'/></linearGradient></defs><path fill='url(%23a)' d='M0 0h40v40H0z'/><path d='M9.835 20.511v-.9h17.5l-8.7-8.7.6-.6 9.7 9.7-9.7 9.7-.6-.6 8.7-8.7h-17.5z' fill='%23333'/></svg>");
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
    padding-right: 58px;
    text-align: left;
    padding-left: 22px;
    min-width: 100%;
    color: $value !important;
    border: none;

    transition: outline 200ms ease-in-out;
    outline: 3px solid rgba(0, 255, 99, 0);

    &.btn-light {
      background-color: $light;
      color: $dark !important;
      &:hover {
        background-color: $light;
        color: $dark !important;
      }
    }
    &.btn-white {
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40'><path fill='%23fff' d='M0 0h40v40H0z'/><path d='M9.835 20.511v-.9h17.5l-8.7-8.7.6-.6 9.7 9.7-9.7 9.7-.6-.6 8.7-8.7h-17.5z' fill='%23333'/></svg>");
      &:hover {
        outline: 3px solid $white;
      }
    }

    &:hover {
      border: none;
      background-color: rgb(51, 51, 51);
      //background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40'><defs><linearGradient id='a'><stop offset='0%' stop-color='%2351eee8'/><stop offset='100%' stop-color='%2352ffa0'/></linearGradient></defs><path fill='url(%23a)' d='M0 0h40v40H0z'/><path d='M9.835 20.511v-.9h17.5l-8.7-8.7.6-.6 9.7 9.7-9.7 9.7-.6-.6 8.7-8.7h-17.5z' fill='%23333'/></svg>");
      outline: 3px solid rgba(0, 255, 99, 1);
      color: $value;
    }

    @include media-breakpoint-up(sm) {
      padding-right: 200px;
      min-width: auto;
      &.btn-sm {
        padding-right: 67px;
      }
    }

    &.btn-simple {
      background-image: none;
      padding-right: 32px;
      padding-left: 32px;
      font-size: 15px;
      border: none;
    }

    &.btn-footer {
      border: 0;
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40'><defs><linearGradient id='a'><stop offset='0%' stop-color='%2351eee8'/><stop offset='100%' stop-color='%2352ffa0'/></linearGradient></defs><path fill='%23333' d='M0 0h40v40H0z'/><path d='M9.835 20.511v-.9h17.5l-8.7-8.7.6-.6 9.7 9.7-9.7 9.7-.6-.6 8.7-8.7h-17.5z' fill='url(%23a)'/></svg>");
      padding-right: 70px;
      min-width: 100%;
      text-align: right;
      background-color: $white;
      color: $dark !important;
      //margin-right: - $card-spacer-y * 1.1;
      //margin-left: - $card-spacer-y * 1.1;

      &:hover {
        //background-color: $dark;
        //color: $white;
      }
    }
  }
}

.cf-arrow-right {
  padding-right: 60px;
  background-color: $white;

  &:after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    height: 100%;
    width: 50px;
    background-position: right;
    background-repeat: no-repeat;
    background-size: 50px;
    background-color: rgb(51, 51, 51);
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40'><defs><linearGradient id='a'><stop offset='0%' stop-color='%2351eee8'/><stop offset='100%' stop-color='%2352ffa0'/></linearGradient></defs><path fill='%23333' d='M0 0h40v40H0z'/><path d='M9.835 20.511v-.9h17.5l-8.7-8.7.6-.6 9.7 9.7-9.7 9.7-.6-.6 8.7-8.7h-17.5z' fill='url(%23a)'/></svg>");
  }

  transition: all 200ms ease-in-out;
  outline: 3px solid rgba(0, 255, 99, 0);
  &:hover {
    outline: 3px solid rgba(0, 255, 99, 1);
  }
}
.cf-cancel {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50' overflow='visible' stroke='black' stroke-width='3' %3E%3Cline x2='50' y2='50' /%3E%3Cline x1='50' y2='50' /%3E%3C/svg%3E");
}
