footer {
  .nav {
    .copyright,
    .nav-link {
      color: rgba(248, 248, 248, 0.5);
      font-weight: bold;
      font-size: 14px;
      letter-spacing: 0.06px;
      line-height: 19px;
      display: inline-block;
      //text-overflow: ellipsis;
      white-space: nowrap;
    }
    .nav-item {
      display: inline-block;
    }
    .copyright {
      font-weight: normal;
      display: block;
    }
    padding-bottom: 50px;
    .navbar-brand {
      margin-right: 0;
    }
  }
  #horizontal-logo-footer {
    margin-top: 10px;
    max-width: 230px;
    @include media-breakpoint-up(md) {
      max-width: 300px;
    }
    margin-bottom: -8px;
  }
}

.bg-light {
  .nav {
    .copyright,
    .nav-link {
      color: $gray-500;
    }
    #horizontal-logo-footer {
      filter: brightness(0.2);
    }
  }
}